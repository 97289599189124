import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {GetPartnerIdFromConfig} from "../../process/config/GetFromConfig";
import {User} from "../../entities/User/User";

type State =
{
  /**
   * User
   */
  user: User,

  /**
   * Partner id
   * used on store order on server
   */
  partnerId: number,

  /**
   * Is mobile menu oen hidden
   */
  showMobileMenu: boolean

  /**
   * Is auth modal open
   */
  showAuthModal: boolean,

  /**
   * Custom logo url used in
   */
  logoUrl : string | undefined
}


const initialState:State =
{
  // load partner id from config
  partnerId: GetPartnerIdFromConfig(),

  user: {
    token: '',
    tokenLifeTime: 0,
    role:  '',
    name: '',
    login: '',
    email: ''
  },

  // flag that mobile menu will shown
  showMobileMenu: false,

  // show auth modal
  // also if on partner page
  showAuthModal: false,

  logoUrl: undefined,
}

if(window.location.hash === "#showAuth") {
  initialState.showAuthModal = true;
}

if (window.sessionStorage) {
  const s = window.localStorage.getItem('vtgCalc');
  if (s) {
    let data = JSON.parse(s)

    // user data
    if (data.appSlice && data.appSlice.user) {
      // todo: check token life time
      initialState.user = data.appSlice.user
    }

  }
}

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    testAction(state, action) {
      console.log ('appSlice.testAction', state, action)
    },
    setShowMobileMenu(state, action:PayloadAction<boolean>) {
      state.showMobileMenu = action.payload
    },
    showAuthMenu (state) {
      state.showAuthModal = true
    },
    hideAuthMenu (state) {
      state.showAuthModal = false
    },
    setLogoUrl(state:State, action:PayloadAction<string> ) {
      state.logoUrl = action.payload
    },
    updateUser(state:State, action:PayloadAction<User>){
      state.user = action.payload
    },
    clearUser(state:State){
      state.user = {
        token: '',
        tokenLifeTime: 0,
        role:  '',
        name: '',
        login: '',
        email: ''
      }
    }
  }
})

export const {
  setShowMobileMenu,
  showAuthMenu,
  hideAuthMenu,
  setLogoUrl,
  updateUser,
  clearUser
} = appSlice.actions

export default appSlice.reducer
